import React from 'react';
import { Fade } from 'react-reveal';
import Image from '../components/Image';
import SyscoFrutier from '../images/sysco-frutiger.svg';
import SyscoOne from '../images/sysco-sample.png';
import Avenir from '../images/avenir-sample.svg';

const SyscoContent = () => { 
  return (
  <div id={'main-content'}>          
    <section className={'project-detail-hero'} >
      <h1 className={'project-name two-piece-combo'} >
        <span className={'slide-top a1'}>Sysco </span> 
        <span className={'slide-top a2'}>Homepage </span>  
        <span className={'slide-top a3'}>Redesign</span>
      </h1>
    <Fade >
      <div className={'contribution two-piece-combo'} >
        <div className={'icon'} >&#xe907;</div>
        <ul>
          <li>User Interface Design</li>
          <li>Digital Branding</li>
          <li>Front-End Development</li>
        </ul>        
      </div>
    </Fade>
    </section>

    <Fade>
    <section className={'project-info'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          The Global Leader
        </h2>
        <Fade fraction={.71}>
        <Image
          alt="Old Sysco Site" 
          filename="old-sysco.png"
          className='old-sysco'
        />
        <p className={'caption'}>
          Old Sysco Site
        </p>
        </Fade>
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          Sysco is the world’s largest broadline food distributor and a Fortune 100 company. Their 600,000+ customers operate through their website and local sales teams. Sysco’s visionary leadership wanted to update their main site. As the interim Design Director, I was tasked with the redesign.
        </p><p>
          Working close with a product lead, it soon became obvious a total site redesign was beyond our scope of work. We decided the most effective step to take would be build, test and measure a new homepage design. This effort will give us a chance to roll back if necessary, while validating a new design.
        </p>

      </div>
    </section>
    </Fade>

    <Fade>
    <section className={'project-info'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          Type-facelift
        </h2>
        <img id={'sysco-frutiger'} src={SyscoFrutier} />
        <p className={'caption frutiger-font'}>
          <span className={'sysco-color'}>Original Logo</span>  /  <span className={'red'}>Frutiger (Next)</span>
        </p>
        <img className={'avenir-sample'} src={Avenir} />
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          The existing site predated a time when web fonts were available, so I needed to start with building a typographic style that reflected Sysco in a fresh way. When researching the Sysco logo I found it to be closest to the font Frutiger, created by the famous type designer Adrian Frutiger. Using a typeface made by the same designer is an easy way to keep a harmonious &amp; clean design.
        </p>
        <p>
          Avenir was selected for body copy, a modern san-serif typeface by Adrian Frutiger.
        </p>
      </div>
    </section>
    </Fade>
    
    
    
    <Fade>
    <section className={'project-info'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          Delivering Fresh
        </h2>
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          I delivered the final design as well as coded the front end (HTML &amp; CSS).
        </p>
      </div>
    </section>
    </Fade>

    <Fade>
    <section id={'sysco-new'} className={'project-info'} >
      <a target="_blank" href="https://web.archive.org/web/20170715231744/http://sysco.com/">
      <img
        src={SyscoOne} 
      />
      </a>
      <p className={'caption'}>
        Click on image to view the site in archive.org
      </p>
    </section>
    </Fade>
  </div>

  )
}

export default SyscoContent;