import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ProjectNav from "../components/projectNav";
import SyscoContent from "../components/sysco-content"
import BackLink from "../components/back-up";
import IndexNav from "../components/project-nav";

export default class Sysco extends Component { 
  constructor() {
    super();
    this.state = {
      brandedBG: true,
      projNav: false
    };
  }
  
  componentDidUpdate() {
    window.onscroll = ('scroll', () => {
      if(window.pageYOffset < 20) {
        this.setState({ brandedBG: true });
      }
      else {
        this.setState({ brandedBG: false });
      }
    });

  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  renderBG = () => {
    if(this.state.brandedBG){
      if(this.state.projNav){
        return 'sysco-bg branded-bg proj-nav-open'
      } else {
        return 'sysco-bg branded-bg proj-nav-close'
      }
    } else{
      return 'default-bg'
    }
  }

  navClick = () => { 
    this.setState({ projNav: !this.state.projNav });
  }

  render() {
    
    return (
      <div className={'project-content'} id={'sysco-home'}>
        <Helmet>
          <body className={this.renderBG()}></body>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"></meta>
          <title>Wes Wong Design: Sysco Homepage Redesign</title>
          <meta name="description" content="Design portfolio of Wes Wong"></meta>
        </Helmet>
        <ProjectNav onClick={this.navClick} />
        
        <IndexNav closeWindow={this.navClick} />

        <SyscoContent />
        <BackLink />
      </div>
    )
  }
}